
//PROYECTOS
$(document).ready(function () {
	//Simple
	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--simple[data-module="swiper-simple"]'), function () {
		var sliderMain = this;
		var $sliderMain = $(sliderMain);
		var slider_speed = $sliderMain.data('speed') || 1200;
		var slider_parent = $sliderMain.data('swiper-heritage');

		var swiper_slider1 = new Swiper(sliderMain, {
			slidesPerView: 1,
			spaceBetween: 20,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			autoplay: false,
			centeredSlides: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets', 
				clickable: true,
			},
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	});

	// //testimonios
	// jQuery.each(jQuery('.swiper--carousel.swiper--carousel--testimonios[data-module="swiper-testimonios"]'), function () {
	// 	var sliderMain = this;
	// 	var $sliderMain = $(sliderMain);
	// 	var slider_speed = $sliderMain.data('speed') || 1200;
	// 	var slider_parent = $sliderMain.data('swiper-heritage');

	// 	var swiper_slider1 = new Swiper(sliderMain, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 20,
	// 		effect: 'slide',
	// 		loop: true,
	// 		speed: slider_speed,
	// 		autoplay: false,
	// 		centeredSlides: false,
	// 		pagination: {
	// 			el: '.swiper-pagination4',
	// 			type: 'bullets',
	// 			clickable: true,
	// 		},
	// 		navigation: {
	// 			nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
	// 			prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
	// 		},
	// 		breakpoints: {
	// 			1080: {
	// 				slidesPerView: 3,  
	// 				spaceBetween: 10
	// 			}
	// 		}
	// 	});
	// });

	// //Simple - black bullets
	// jQuery.each(jQuery('.swiper--carousel.swiper--carousel--simple-black[data-module="swiper-simple-black"]'), function () {
	// 	var sliderMain = this;
	// 	var $sliderMain = $(sliderMain);
	// 	var slider_speed = $sliderMain.data('speed') || 1000;
	// 	var slider_parent = $sliderMain.data('swiper-heritage');

	// 	var swiper_slider1 = new Swiper(sliderMain, {
	// 		slidesPerView: 1,
	// 		spaceBetween: 10,
	// 		effect: 'slide',
	// 		loop: true,
	// 		speed: slider_speed,
	// 		autoplay: false,
	// 		centeredSlides: true,
	// 		pagination: {
	// 			el: '.swiper-pagination5',
	// 			type: 'bullets',
	// 			clickable: true,
	// 		},
	// 		navigation: {
	// 			nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
	// 			prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
	// 		},
	// 		breakpoints: {
	// 			480: {
	// 				slidesPerView: 1,
	// 				spaceBetween: 10
	// 			}
	// 		}
	// 	});
	// });
});


